import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  ORDER_STATUS,
  ORDER_STATUS_LIST,
  PAYMENT_METHODS,
  PAYMENT_STATUS,
  TABLE_PAYMENT_METHOD,
  TABLE_PAYMENT_STATUS,
  ordersSearchByOptions,
} from "helpers/variables.js";
import { OrdersTableColumns } from "helpers/tableDataMapper";
import * as orders from "actions/orders";
import * as deliveryBoyAction from "actions/deliveryboy";
import * as shopAction from "actions/shops";
import { SearchWrapper } from "components/Table/SearchWrapper";
import Topbar from "components/topbar";
import { formatDate, handleTableCellData } from "helpers";
import { getSNo, getValidValue } from "helpers/utils";
import { CustomDatePicker } from "components/inputs/Datepicker";
import FormInput from "components/inputs/FormInput";
import { TableWrapper } from "components/TableWrapper";
import ReactSelect from "components/inputs/ReactSelect";
import { TableActionbtns } from "components/TableWrapper/tableActionBtns";

const defaultPayload = {
  search: "",
  startDate: "",
  endDate: "",
  status: "",
  paymentStatus: "",
  // paymentMethod: '',
  shopId: "",
  page: 1,
  limit: 50,
};

class OrdersClass extends Component {
  state = {
    queryPayload: {
      search: "",
      startDate: "",
      endDate: "",
      status: "",
      // userMaster_Id: '',
      shopId: "",
      // paymentMethod: '',
      paymentStatus: "",
      page: 1,
      limit: 50,
    },
    showWrapper: false,
    selectedOrderDetail: {},
    orderStatusDropList: ORDER_STATUS_LIST,
    // paymentMethodList: PAYMENT_METHODS,
    paymentStatusList: PAYMENT_STATUS,
  };

  componentDidMount() {
    // this.getAllDeliveryBoys()
    this.getApplicationList();
    this.props.shopAction.getShopsDropDownList();
  }

  getAllDeliveryBoys = async () => {
    // this.props.deliveryBoyAction.getAllDeliveryBoys()
  };

  onChange = (name, value) => {
    let { queryPayload } = this.state;
    queryPayload[name] = value;
    this.setState({
      queryPayload,
    });
  };

  nextpage = (page) => {
    let { queryPayload } = this.state;
    queryPayload["page"] = page;
    this.setState({ queryPayload }, () => this.triggerApi(page));
  };

  getApplicationDetails = (data) => {
    this.props.ordersAction.getBookingDetailsFromId(data);
    if (!this.props.isDetailMode) this.props.toggle();
  };

  getApplicationList = () => {
    let { queryPayload } = this.state;
    this.props.ordersAction.getOrdersList(queryPayload);
  };

  triggerApi = async (page) => {
    let { queryPayload } = this.state;

    const payload = {
      ...queryPayload,
      page: page,
      status: getValidValue(queryPayload?.status, "select"),
      shopId: getValidValue(queryPayload?.shopId, "select"),
      // userMastr_Id: getValidValue(queryPayload?.userMaster_Id, 'select'),
      // paymentMethod: getValidValue(queryPayload?.paymentMethod, 'select'),
      paymentStatus: getValidValue(queryPayload?.paymentStatus, "select"),
    };

    if (queryPayload.startDate) {
      payload["startDate"] = moment(queryPayload.startDate).format(
        "YYYY-MM-DD"
      );
    }

    if (queryPayload.startDate) {
      payload["endDate"] = moment(queryPayload.endDate).format("YYYY-MM-DD");
    }
    console.log("payload", payload);
    await this.props.ordersAction.getOrdersList(payload);
  };

  clearFilters = () => {
    this.setState(
      {
        queryPayload: { ...defaultPayload },
      },
      this.getApplicationList
    );
  };

  toggleWrapper = (order) => {
    this.setState({
      showWrapper: true,
      selectedOrderDetail: order,
    });
  };
  unToggleWrapper = () => {
    this.setState({
      showWrapper: false,
    });
  };

  render() {
    let {
      queryPayload,
      orderStatusDropList,
      paymentMethodList,
      paymentStatusList,
    } = this.state;
    let { orders, loader, shops } = this.props;

    return (
      <div>
        <Topbar
          pageName="Orders"
          createBtnLabel=""
          showBtn={false}
          onPressCreateBtn={() =>
            this.props.history.push("/staff/orders/detail")
          }
        />
        <SearchWrapper
          applyFilter={() => this.triggerApi(1)}
          clearFilters={() => this.clearFilters()}
        >
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
              <ReactSelect
                type="select"
                name="status"
                label="Order Status"
                placeholder="Select Order Status"
                options={orderStatusDropList}
                isClearable={true}
                onChange={(...params) => this.onChange(...params)}
                value={queryPayload["status"]}
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
              <ReactSelect
                onChange={(...params) => this.onChange(...params)}
                options={shops?.shopsDropdownList}
                name={"shopId"}
                label="Select Shop"
                value={queryPayload["shopId"]}
                isMulti={false}
                isClearable={true}
                placeholder={"Select Shop"}
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
              <CustomDatePicker
                name="startDate"
                label="Choose Start Date"
                placeholder="Choose Start Date"
                onChange={(...params) => this.onChange(...params)}
                value={queryPayload["startDate"]}
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
              <CustomDatePicker
                name="endDate"
                label="Choose End Date"
                placeholder="Choose End Date"
                onChange={(...params) => this.onChange(...params)}
                value={queryPayload["endDate"]}
              />
            </div>
            {/* <div className='col-xl-3 col-lg-3 col-md-4 custom-reselect col-12  mb-3'>
                            <ReactSelect
                                onChange={(...params) => this.onChange(...params)}
                                options={deliveryBoy?.allDeliveryBoys}
                                name={"userMaster_Id"}
                                label="Filter By Delivery Partner"
                                value={queryPayload["userMaster_Id"]}
                                isMulti={false}
                                isClearable={true}
                                placeholder={"Select"}
                            />
                        </div> */}
            {/* <div className='col-xl-3 col-lg-3 col-md-4 custom-reselect col-12  mb-3'>
                            <ReactSelect
                                onChange={(...params) => this.onChange(...params)}
                                options={paymentMethodList}
                                name={"paymentMethod"}
                                label="Choose Payment Method"
                                value={queryPayload["paymentMethod"]}
                                isMulti={false}
                                isClearable={true}
                                placeholder={"Select"}
                            />
                        </div> */}
            <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12  mb-3">
              <ReactSelect
                onChange={(...params) => this.onChange(...params)}
                options={paymentStatusList}
                name={"paymentStatus"}
                label="Choose Payment Status"
                value={queryPayload["paymentStatus"]}
                isMulti={false}
                isClearable={true}
                placeholder={"Select"}
              />
            </div>
            {/* <div className='col-xl-3 col-lg-3 col-md-4 custom-reselect col-12  mb-3'>
                            <ReactSelect
                                type="select"
                                name="searchBy"
                                label="Choose Search By"
                                placeholder="Search By"
                                options={ordersSearchByOptions}
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["searchBy"]}
                            />
                        </div> */}
            <div className="col-xl-3 col-lg-3 col-md-4 col-12  mb-3">
              <FormInput
                type="text"
                name="search"
                label="Search"
                placeholder="Search By Customer name, Mobile Number"
                onChange={(...params) => this.onChange(...params)}
                value={queryPayload["search"]}
              />
            </div>
          </div>
        </SearchWrapper>
        <TableWrapper
          headerDetails={OrdersTableColumns}
          pageMeta={orders?.ordersList?.pageMeta}
          listLength={orders?.ordersList?.list || []}
          isLoading={loader.isOrdersListFetching}
          handlePageChange={this.nextpage}
          highlightRows
          tableClassName="mt-3"
        >
          {orders?.ordersList?.list?.map((item, index) => {
            const {
              name = "",
              mobileNumber = "",
              itemTotal = "",
              deliveryCharge = "",
              shopName = "",
              _id = "",
              createdAt = "",
              status = "",
              totalAmount = "",
              paymentStatus = "",
              paymentMethod = "",
              areaName = "",
            } = item;
            return (
              <tr>
                {handleTableCellData(
                  getSNo(
                    index,
                    orders?.ordersList?.pageMeta?.page,
                    orders?.ordersList?.pageMeta?.limit
                  ),
                  "-",
                  "text-center"
                )}
                {handleTableCellData(
                  _id.slice(_id.length - 6),
                  "-",
                  "text-center"
                )}
                {handleTableCellData(name, "-", "text-center")}
                {handleTableCellData(mobileNumber, "-", "text-center")}
                {handleTableCellData(
                  shopName?.slice(0, 6) + areaName,
                  "-",
                  "text-center"
                )}
                {handleTableCellData(itemTotal, "-", "text-center")}
                {handleTableCellData(deliveryCharge, "-", "text-center")}
                {handleTableCellData(
                  totalAmount && !isNaN(totalAmount)
                    ? totalAmount.toFixed(2)
                    : totalAmount,
                  "-",
                  "text-center"
                )}
                <td>
                  {status === ORDER_STATUS.ORDER_CREATED ? (
                    <p className="table_order_status unapproved_item">
                      Order Created
                    </p>
                  ) : status === ORDER_STATUS.CONFIRMED ? (
                    <p className="table_order_status confirmed_item">
                      Confirmed
                    </p>
                  ) : status === ORDER_STATUS.PICKEDUP ? (
                    <p className="table_order_status picked_item">Pickedup</p>
                  ) : status === ORDER_STATUS.DELIVERED ? (
                    <p className="table_order_status active_item">Delivered</p>
                  ) : status === ORDER_STATUS.CANCELLED ? (
                    <p className="table_order_status cancelled_item">
                      Cancelled
                    </p>
                  ) : status === ORDER_STATUS.READY_TO_DELIVER ? (
                    <p className="table_order_status readyTodeliver_item">
                      Ready To Deliver
                    </p>
                  ) : (
                    <p>-</p>
                  )}
                </td>
                <td>
                  {paymentMethod === TABLE_PAYMENT_METHOD.CASH ? (
                    <p className="table_order_status cash">CASH</p>
                  ) : paymentMethod === TABLE_PAYMENT_METHOD.ONLINE ? (
                    <p className="table_order_status online">ONLINE</p>
                  ) : (
                    <p>-</p>
                  )}
                </td>
                <td>
                  {status !== ORDER_STATUS.CANCELLED ? (
                    paymentStatus === TABLE_PAYMENT_STATUS.PAYMENT_SUCCESS ? (
                      <p className="table_order_status paid">PAID</p>
                    ) : paymentStatus ===
                      TABLE_PAYMENT_STATUS.PAYMENT_PENDING ? (
                      <p className="table_order_status not_paid">NOT PAID</p>
                    ) : paymentStatus ===
                      TABLE_PAYMENT_STATUS.PAYMENT_FAILED ? (
                      <p className="table_order_status not_paid">
                        Payment Failed
                      </p>
                    ) : (
                      <p>-</p>
                    )
                  ) : (
                    <p>-</p>
                  )}
                </td>
                {handleTableCellData(formatDate(createdAt), "-", "text-center")}
                <td>
                  <TableActionbtns
                    item={item}
                    showDelete={false}
                    showEdit={false}
                    showView={true}
                    viewItem={() => this.getApplicationDetails(item)}
                    handleSelectRow={() =>
                      this.props.history.push(`/staff/orders/detail/${_id}`)
                    }
                  />
                </td>
              </tr>
            );
          })}
        </TableWrapper>
        {/* {showWrapper &&
                    <div className='show-details'>
                        <div className={`staffDetails assesment-card` }>
                            {selectedOrderDetail?._id}
                        </div>
                        </div>
                        
                } */}
      </div>
    );
  }
}

const mapStateToProps = ({ orders, profile, loader, deliveryBoy, shops }) => {
  return {
    orders,
    profile,
    loader,
    deliveryBoy,
    shops,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ordersAction: bindActionCreators(orders, dispatch),
    deliveryBoyAction: bindActionCreators(deliveryBoyAction, dispatch),
    shopAction: bindActionCreators(shopAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersClass);
