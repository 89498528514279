import React from 'react'
import Logo from 'assets/images/logo.png';

const AuthCard = ({ children, heading}) => {
    return (
        <div className='authcard'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-8 col-lg-7 col-md-12 bg-img"></div>
                    <div className="col-xl-4 col-lg-5 col-md-0 col-sm-12 form-info ">
                        <div className="form-section">
                            {/* <div className="logo">
                                <img src={Logo} alt="logo" />
                            </div> */}
                            <div className="auth-header-content">
                                <h1 className="title">
                                    {heading}
                                </h1>
                            </div>
                            <div className="login-inner-form">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthCard;