import React from "react";
import { formatDate } from "helpers";

export const ShopPaymentTableList = {
  columns: [
    {
      header: "Shop ID",
      accessor: "shopId",
    },
    {
      header: "Shop Name",
      accessor: "shopName",
    },
    {
      header: "Mobile Number",
      accessor: "shopMobileNumber",
    },
    {
      header: "Total Amount",
      accessor: "totalAmount",
    },
    {
      header: "Amount Sent",
      accessor: "amountSent",
    },
    {
      header: "Pending Amount To Be Sent",
      accessor: "pendingAmountToBeSent",
    },
    {
      header: "Created At",
      accessor: "createdAt",
      formatter: (cellContent, row, rowIndex) => {
        return formatDate(cellContent);
      },
    },
  ],
  rows: [],
};

export const CustomerPaymentTableList = [
  {
    dataField: "S.No",
    text: "S.No.",
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    },
  },
  {
    text: "Payment Id",
    dataField: "customerPaymentId",
  },
  {
    text: "Customer ID",
    dataField: "userId",
  },
  {
    text: "Order ID",
    dataField: "orderId",
  },
  {
    text: "Customer Name",
    dataField: "name",
  },
  {
    text: "Mobile Number",
    dataField: "mobileNumber",
  },
  {
    text: "Total Amount",
    dataField: "totalAmount",
  },
  {
    text: "payment Type",
    dataField: "paymentType",
  },
  {
    text: "status",
    dataField: "status",
  },
  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (cellContent, row, rowIndex) => {
      return formatDate(cellContent);
    },
  },
];

export const RevenueListTable = [
  {
    dataField: "shopId",
    text: "Shop ID",
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    },
  },
  {
    text: "Shop Name",
    dataField: "shopName",
  },
  {
    text: "Mobile Number",
    dataField: "shopMobileNumber",
  },
  {
    text: "Total Revenue Generated",
    dataField: "totalRevenueGenerated",
    formatter: (cellContent, row, rowIndex) => {
      return parseInt(row.totalRevenueGenerated);
    },
  },
  {
    text: "Total Delivery Charge",
    dataField: "totalDeliveryCharge",
  },
  {
    text: "Final Revenue Generated",
    dataField: "totalAmount",
    formatter: (cellContent, row, rowIndex) => {
      return (
        parseInt(row.totalRevenueGenerated) - parseInt(row.totalDeliveryCharge)
      );
    },
  },
];

export const DeliveryBoyPaymentsTable = {
  columns: [
    {
      header: "Delivery Boy ID",
      accessor: "userMasterId",
    },
    {
      header: "Delivery Boy Name",
      accessor: "name",
    },
    {
      header: "Mobile Number",
      accessor: "mobileNumber",
    },
    {
      header: "Total Amount",
      accessor: "totalDeliveryCharge",
    },
    {
      header: "Amount Sent",
      accessor: "amountSent",
    },
    {
      header: "Pending Amount To Be Sent",
      accessor: "pendingAmountToBeSent",
    },
  ],
  rows: [],
};

export const BannerTableList = [
  {
    text: "Banner type",
    dataField: "type",
    headerClasses: "columnHeader",
    sort: true,
    formatter: (cellContent, row, rowIndex) => {
      return row.type == "1"
        ? "HOME BANNER"
        : row.type == "2"
        ? "FSE BANNER"
        : "SUBSCRIPTION BANNER";
    },
  },
  {
    text: "Banner",
    dataField: "banners",
    headerClasses: "columnHeader",
    sort: true,
    formatter: (cellContent, row, rowIndex) => {
      return cellContent.map((cell) => (
        <img src={cell} style={{ width: "100px", marginRight: "10px" }} />
      ));
    },
  },
  {
    text: "Created At",
    dataField: "createdAt",
    headerClasses: "columnHeader",
    sort: true,
    formatter: (cellContent, row, rowIndex) => {
      return formatDate(cellContent);
    },
  },
];

// new
export const CustomerColumns = [
  { label: "S.No" },
  { label: "#" },
  { label: "Customer Name" },
  { label: "Mobile Number" },
  { label: "Email Id" },
  { label: "Signed up On" },
  { label: "Status" },
  { label: "Actions" },
];

export const TopCustomersColumns = [
  { label: "S.No" },
  { label: "Customer Name" },
  { label: "Customer Mobile Number" },
  { label: "Number Of Orders" },
];

export const CustomerOrdersHistoryColumns = [
  { label: "S.No" },
  { label: "#" },
  { label: "Order Detail" },
  { label: "Order Amount" },
  { label: "Delivery Charge" },
  { label: "Total Amount" },
  { label: "Status" },
  { label: "Ordered At" },
  { label: "Updated At" },
];

export const OrdersTableColumns = [
  { label: "S.No" },
  { label: "#" },
  { label: "Name" },
  { label: "Number" },
  { label: "Shop" },
  { label: "Amount" },
  { label: "Charge" },
  { label: "Total" },
  { label: "Status" },
  { label: "Payment Method" },
  { label: "Payment Status" },
  { label: "Ordered At" },
  { label: "Actions" },
];

export const ShopsTableColumns = [
  { label: "S.No" },
  { label: "#" },
  { label: "Shop Name" },
  { label: "Adresss" },
  { label: "shop Timing" },
  { label: "Image" },
  { label: "Mobile Number" },
  // { label: "Shop Status" },
  { label: "Actions" },
];

export const NotificationTableColumns = [
  { label: "S.No" },
  { label: "Title" },
  { label: "Description" },
  { label: "Image" },
  { label: "Trigger" },
  { label: "Actions" },
];

export const CategoryColumns = [
  { label: "S.No" },
  { label: "#" },
  { label: "Category" },
  { label: "Image" },
  { label: "Status" },
  { label: "Created At" },
  { label: "Actions" },
];

export const ProductColumns = [
  { label: "S.No" },
  { label: "#" },
  { label: "Product Name" },
  { label: "Category" },
  { label: "Quantity" },
  { label: "Product Price" },
  { label: "Slashed Price" },
  { label: "Status" },
  { label: "Image" },
  { label: "Actions" },
];

export const ProductWiseProfitColumns = [
  { label: "S.No" },
  { label: "#" },
  { label: "Product Name" },
  { label: "Shop" },
  { label: "Category" },
  { label: "Selling Price" },
  { label: "Cost Price" },
  { label: "Profit (%)" },
];

export const UserManagementTableColumns = [
  { label: "S.No" },
  { label: "#" },
  { label: "Name" },
  { label: "Mobile Number" },
  { label: "Role" },
  { label: "Status" },
  { label: "Actions" },
];

export const DeliveryPersonPastOrdersColumns = [
  { label: "S.No" },
  { label: "Customer Name" },
  { label: "Total" },
  { label: "Shop Name" },
  { label: "status" },
  { label: "paymentMethod" },
  { label: "paymentStatus" },
  { label: "Delivered On" },
];

export const SupportColumns = [
  { label: "S.No" },
  { label: "First Name" },
  { label: "Last Name" },
  { label: "Email" },
  { label: "Mobile Number" },
  { label: "Message" },
];
