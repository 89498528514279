import React, { Component } from "react";
import { Row, Col, Button, CustomInput } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CategoryColumns, SupportColumns } from "helpers/tableDataMapper";
import * as support from "actions/support";
import FormInput from "components/inputs/FormInput";
import { CreateModel } from "components/Model";
import Uploadfile from "components/inputs/Uploadfile";
import { Toast } from "helpers/Toast";
import { API, API_BOOK } from "service";
import { SearchWrapper } from "components/Table/SearchWrapper";
import validate from "helpers/validation";
import Topbar from "components/topbar";
import { formatDate, handleTableCellData } from "helpers";
import { getSNo } from "helpers/utils";
import { TableWrapper } from "components/TableWrapper";
import { TableActionbtns } from "components/TableWrapper/tableActionBtns";

const defaultPayload = {
  search: "",
  startDate: "",
  page: 1,
  limit: 50,
};

class AddCategoryClass extends Component {
  defaultcategoryObject = {
    _id: "",
    categoryName: "",
    categoryImageUrl: null,
  };
  state = {
    activeTab: [1],
    queryPayload: {
      search: null,
      page: 1,
      limit: 50,
    },
    categoryDetail: {
      _id: null,
      categoryName: null,
      categoryImageUrl: null,
    },
    errors: {
      categoryName: null,
      categoryImageUrl: null,
    },
    init: true,
    openCreatePopup: false,
    btnLoading: false,
    isUploadingImage: false,
    showDeletePopup: false,
    deleteData: null,
  };
  componentDidMount() {
    this.getSupportList();
  }

  clearFilters = () => {
    this.setState(
      {
        queryPayload: { ...defaultPayload },
      },
      this.getCategoryList
    );
  };

  nextpage = (page) => {
    let { queryPayload } = this.state;
    queryPayload["page"] = page;
    this.setState({ queryPayload }, () => this.getCategoryList(page));
  };

  getSupportList = (page = 1) => {
    let { queryPayload } = this.state;
    queryPayload["page"] = page;
    this.props.getSupportList(queryPayload);
  };

  onChangeQueryPayload = (name, value) => {
    let { queryPayload } = this.state;
    queryPayload[name] = value;
    this.setState({
      queryPayload,
    });
  };

  onChange = (name, value) => {
    let { categoryDetail } = this.state;
    categoryDetail[name] = value;
    this.setState({ categoryDetail });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { categoryDetail, isEdit } = this.state;

    const notValid = validate(categoryDetail, inputValidations);
    if (notValid) {
      this.setState({
        errors: notValid,
      });
    } else {
      this.setState({
        btnLoading: true,
      });
      let payload = {
        categoryName: categoryDetail.categoryName,
        categoryImageUrl: categoryDetail.categoryImageUrl,
      };
      let response = null;
      if (isEdit) {
        payload["_id"] = categoryDetail._id;
        response = await this.props.updateCategory(payload);
      } else {
        response = await this.props.createCategory(payload);
      }
      if (response != null && response.status) {
        this.setState(
          {
            openCreatePopup: false,
            btnLoading: false,
            categoryDetail: { ...this.defaultcategoryObject },
          },
          this.getCategoryList
        );
      } else {
        this.setState({
          btnLoading: false,
        });
      }
    }
  };

  handleSelectRow = (data) => {
    const payload = {
      _id: data._id,
      categoryName: data.categoryName,
      categoryImageUrl: data.categoryImageUrl,
    };
    this.setState({
      openCreatePopup: true,
      isEdit: true,
      categoryDetail: payload,
    });
  };

  toggle = () => {
    this.setState({
      openCreatePopup: !this.state.openCreatePopup,
      categoryDetail: { ...this.defaultcategoryObject },
    });
  };

  onUpload = async (name, files) => {
    let { errors, categoryDetail } = this.state;
    errors[name] = undefined;
    let formData = new FormData();
    formData.append("image", files[0]);
    this.setState({ isUploadingImage: true });
    const { UPLOAD_DOC_API } = API_BOOK.MAIN_APP;
    let requestPayload = {
      ...UPLOAD_DOC_API,
      data: formData,
    };
    let response = await API(requestPayload);
    if (response.status === 200) {
      const newDetail = {
        ...categoryDetail,
        categoryImageUrl: response.data.data.imageUrl,
      };
      this.setState({
        categoryDetail: newDetail,
        isUploadingImage: false,
      });
    } else {
      Toast({ type: "error", message: response.data.message });
    }
    this.setState({ isUploadingImage: false });
  };

  toggleCategoryStatus = (category) => {
    let data = {
      _id: category._id,
      status: category.status === 1 ? 2 : 1,
    };
    this.props.toggleCategoryStatus(data);
  };

  deleteRow = (row) => {
    let data = {
      _id: row._id,
    };
    this.setState({
      showDeletePopup: true,
      deleteData: data,
    });
  };

  onSubmitDelete = async () => {
    let { deleteData } = this.state;
    await this.props.deleteCategory(deleteData);
    this.setState({ showDeletePopup: false }, this.getCategoryList);
  };

  toggleDelete = () => {
    this.setState({
      showDeletePopup: false,
    });
  };

  render() {
    let { loader, support } = this.props;
    
    return (
      <>
        <Topbar
          pageName="Support Enquiries"
          showBtn={false}
          createBtnLabel="Create Category"
          onPressCreateBtn={() => this.toggle()}
        />
        <TableWrapper
          headerDetails={SupportColumns}
          pageMeta={support.supportList?.pageMeta}
          listLength={support.supportList?.list || []}
          isLoading={loader.isTableDetailsFetching}
          handlePageChange={this.nextpage}
          highlightRows
          tableClassName="mt-3"
        >
          {support?.supportList?.list?.map((item, index) => {
            const {
              _id = "",
              firstName = "",
              lastName = "",
              email = "",
              mobileNumber = "",
              message = "",
            } = item;
            return (
              <tr key={_id}>
                {handleTableCellData(
                  getSNo(
                    index,
                    support.SupportList?.pageMeta?.page,
                    support.SupportList?.pageMeta?.limit
                  ),
                  "-",
                  "text-center"
                )}
                {/* {handleTableCellData(
                  _id.slice(_id.length - 6),
                  "-",
                  "text-center"
                )} */}
                {handleTableCellData(firstName, "-", "text-center")}
                {handleTableCellData(lastName, "-", "text-center")}
                {handleTableCellData(email, "-", "text-center")}
                {handleTableCellData(mobileNumber, "-", "text-center")}

                {handleTableCellData(message, "-", "text-center")}
              </tr>
            );
          })}
        </TableWrapper>
      </>
    );
  }
}

const mapStateToProps = ({ support, profile, loader }) => {
  console.log("support", support);
  return {
    support,
    profile,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(support, dispatch);
};

const Support = connect(mapStateToProps, mapDispatchToProps)(AddCategoryClass);

export { Support };

let inputValidations = {
  categoryName: {
    presence: {
      allowEmpty: false,
      message: "^category Name can't be blank",
    },
  },
  categoryImageUrl: {
    presence: {
      allowEmpty: false,
      message: "^Category Image can't be blank",
    },
  },
};
