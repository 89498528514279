import React, { Component } from "react";
import { Row, Col, Button, CustomInput } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ProductColumns } from "helpers/tableDataMapper";
import * as shops from "actions/shops";
import FormInput from "components/inputs/FormInput";
import { Toast } from "helpers/Toast";
import { CreateModel } from "components/Model";
import ReactSelect from "components/inputs/ReactSelect";
import { API, API_BOOK } from "service";
import { SearchWrapper } from "components/Table/SearchWrapper";
import Loader from "components/loader/formLoader";
import Uploadfile from "components/inputs/Uploadfile";
import ButtonType1 from "components/buttons/ButtonType1";
import validate from "helpers/validation";
import Topbar from "components/topbar";
import { TableWrapper } from "components/TableWrapper";
import { handleTableCellData } from "helpers";
import { getSNo } from "helpers/utils";
import { TableActionbtns } from "components/TableWrapper/tableActionBtns";

const defaultPayload = {
  search: "",
  categoryId: "",
  startDate: "",
  page: 1,
  limit: 100,
};

class AddProductClass extends Component {
  defaultproductObject = {
    categoryId: null,
    productName: null,
    quantity: null,
    productDescription: null,
    productPrice: null,
    slashedPrice: null,
    productImageUrl: null,
    addonIds: [],
  };
  state = {
    queryPayload: {
      categoryId: null,
      search: null,
      page: 1,
      limit: 100,
    },
    categoriesDropdown: [],
    addonDropdown: [],
    products: [],
    errors: {
      productName: null,
      quantity: null,
      productPrice: null,
      slashedPrice: null,
      productImageUrl: null,
      categoryId: null,
      type: null,
      productDescription: null,
    },
    productDetails: {
      productName: null,
      quantity: null,
      productPrice: null,
      slashedPrice: null,
      productImageUrl: null,
      categoryId: null,
      type: null,
      productDescription: null,
      addonIds: [],
    },
    categoryDropdown: [],
    openCreatePopup: false,
    popupLoading: false,
    isUploadingImage: false,
    showDeletePopup: false,
    deleteData: null,
    isEdit: false,
    btnLoading: false,
  };

  componentDidMount() {
    this.getProductList();
    this.getCategoryDropdown();
    this.getAddonDropdown();
  }

  getCategoryDropdown = async () => {
    try {
      let { GET_CATEGORY_DROPDOWN_API } = API_BOOK.MAIN_APP;
      let requestPayload = {
        ...GET_CATEGORY_DROPDOWN_API,
      };
      let response = await API(requestPayload);
      if (response.status === 200) {
        this.setState({
          categoryDropdown: response.data.data,
        });
      } else {
        this.setState({
          categoriesDropdown: [],
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error?.data?.message });
    }
  };

  getAddonDropdown = async () => {
    try {
      let { GET_ADDON_DROPDOWN_API } = API_BOOK.MAIN_APP;
      let requestPayload = {
        ...GET_ADDON_DROPDOWN_API,
      };
      let response = await API(requestPayload);
      if (response.status === 200) {
        this.setState({
          addonDropdown: response.data.data,
        });
      } else {
        this.setState({
          addonDropdown: [],
        });
      }
    } catch (error) {
      Toast({ type: "error", message: error?.data?.message });
    }
  };

  nextpage = (page) => {
    let { queryPayload } = this.state;
    queryPayload["page"] = page;
    this.setState({ queryPayload }, () => this.getProductList(page));
  };

  getProductList = (page = 1) => {
    const { queryPayload } = this.state;
    let newPaylaod = { ...queryPayload };

    newPaylaod["page"] = page;
    this.props.getProductList(newPaylaod);
  };

  onChangeQueryPayload = (name, value) => {
    let { queryPayload } = this.state;
    queryPayload[name] = value;
    this.setState({
      queryPayload,
    });
  };

  onChange = (name, value) => {
    let { productDetails } = this.state;
    productDetails[name] = value;
    this.setState({ productDetails });
  };

  onSubmit = async () => {
    let { productDetails, isEdit } = this.state;
    console.log("productDetails", productDetails.addonIds);
    let validation = {
      ...inputValidations,
    };
    const notValid = validate(productDetails, validation);
    if (notValid) {
      this.setState({
        errors: notValid,
      });
    } else {
      this.setState({ btnLoading: true });
      let payload = {
        productName: productDetails?.productName,
        quantity: productDetails?.quantity,
        productPrice: productDetails?.productPrice,
        slashedPrice: productDetails?.slashedPrice,
        productImageUrl: productDetails?.productImageUrl,
        productDescription: productDetails?.productDescription,
        categoryId: productDetails.categoryId?.value,
        addonIds:
          productDetails.addonIds && productDetails.addonIds.length > 0
            ? productDetails.addonIds.map((pr) => pr.value)
            : [],
      };
      let response = null;
      if (isEdit) {
        payload["_id"] = productDetails._id;
        response = await this.props.updateProduct(payload);
      } else {
        response = await this.props.createProduct(payload);
      }
      if (response && response.status) {
        this.setState({
          openCreatePopup: false,
          btnLoading: false,
          productDetails: { ...this.defaultproductObject },
        });
      } else {
        this.setState({
          btnLoading: false,
        });
      }
      this.getProductList();
    }
  };
  handleSelectRow = async (data) => {
    let { addonDropdown } = this.state;
    let payload = {
      ...data,
      categoryId: { label: data.categoryName, value: data.categoryId },
      addonIds:
        data.addonIds && data.addonIds.length > 0
          ? addonDropdown.filter((item) => data.addonIds.includes(item.value))
          : [],
      categoryName: data.categoryName,
      status: data.status,
    };
    this.setState({
      openCreatePopup: true,
      productDetails: payload,
      popupLoading: false,
      isEdit: true,
    });
  };

  createNewProduct = () => {
    this.setState({
      openCreatePopup: !this.state.openCreatePopup,
    });
  };

  toggle = () => {
    this.setState({
      openCreatePopup: !this.state.openCreatePopup,
      productDetails: { ...this.defaultproductObject },
    });
  };

  toggleChecked = (row) => {
    const data = {
      _id: row._id,
      status: row.status === 1 ? 2 : 1,
    };
    this.props.toggleProductStatus(data);
  };

  deleteRow = (row) => {
    let data = {
      _id: row._id,
      productName: row.productName,
    };
    this.setState({
      showDeletePopup: true,
      deleteData: data,
    });
  };

  onUpload = async (name, files) => {
    let { errors, productDetails } = this.state;
    errors[name] = undefined;
    let formData = new FormData();
    formData.append("image", files[0]);
    this.setState({ isUploadingImage: true });
    const { UPLOAD_DOC_API } = API_BOOK.MAIN_APP;
    let requestPayload = {
      ...UPLOAD_DOC_API,
      data: formData,
    };
    let response = await API(requestPayload);
    if (response.status === 200) {
      productDetails.productImageUrl = response.data.data.imageUrl;
      this.setState({
        productDetails,
        isUploadingImage: false,
      });
    } else {
      Toast({ type: "error", message: response.data.message });
    }
  };
  onSubmitDelete = async () => {
    let { deleteData } = this.state;
    await this.props.deleteProduct(deleteData);
    this.setState({ showDeletePopup: false }, this.getProductList);
  };
  toggleDelete = () => {
    this.setState({
      showDeletePopup: false,
    });
  };
  clearFilters = () => {
    this.setState(
      {
        queryPayload: { ...defaultPayload },
      },
      this.getProductList
    );
  };
  render() {
    let { loader, shops } = this.props;
    let {
      addonDropdown,
      queryPayload,
      errors,
      popupLoading,
      productDetails,
      categoryDropdown,
      showDeletePopup,
      isUploadingImage,
      btnLoading,
    } = this.state;
    return (
      <>
        <Topbar
          pageName="Products"
          showBtn={true}
          createBtnLabel="Create Product"
          onPressCreateBtn={() => this.toggle()}
        />
        <SearchWrapper
          applyFilter={() => this.getProductList()}
          clearFilters={() => this.clearFilters()}
        >
          <div className="row">
            <div className="col-lg-3 col-xl-3 col-md-4 col-12  mb-3">
              <FormInput
                type="text"
                name="search"
                label="Search"
                placeholder="Search.."
                onChange={(...params) => this.onChangeQueryPayload(...params)}
                value={queryPayload["search"]}
              />
            </div>
          </div>
        </SearchWrapper>
        <TableWrapper
          headerDetails={ProductColumns}
          pageMeta={shops.productsList?.pageMeta}
          listLength={shops.productsList?.list || []}
          isLoading={loader.isProductsListFetching}
          handlePageChange={this.nextpage}
          highlightRows
          tableClassName="mt-3"
        >
          {shops.productsList?.list?.map((item, index) => {
            const {
              productName = "",
              _id = "",
              categoryName = "",
              productImageUrl = "",
              status = "",
              createdAt = "",
              quantity = "",
              productPrice = "",
              slashedPrice = "",
            } = item;
            return (
              <tr>
                {handleTableCellData(
                  getSNo(
                    index,
                    shops.productsList?.pageMeta?.page,
                    shops.productsList?.pageMeta?.limit
                  ),
                  "-",
                  "text-center"
                )}
                {handleTableCellData(
                  _id.slice(_id.length - 6),
                  "-",
                  "text-center"
                )}
                {handleTableCellData(productName, "-", "text-center")}

                {handleTableCellData(categoryName, "-", "text-center")}
                {handleTableCellData(quantity, "text-center")}
                {handleTableCellData(productPrice, "-", "text-center")}
                {handleTableCellData(slashedPrice, "-", "text-center")}
                <td>
                  <CustomInput
                    type="switch"
                    id={_id}
                    name="status"
                    inline={true}
                    label={status === 1 ? "ON" : "OFF"}
                    checked={status === 1 ? true : false}
                    onChange={() => this.toggleChecked(item)}
                  />
                </td>
                <td>
                  <img
                    src={productImageUrl}
                    style={{ width: "75px", height: "75px", borderRadius: 5 }}
                    alt="product"
                  />
                </td>

                <td>
                  <TableActionbtns
                    item={item}
                    deleteRow={this.deleteRow}
                    handleSelectRow={this.handleSelectRow}
                  />
                </td>
              </tr>
            );
          })}
        </TableWrapper>
        <CreateModel
          isOpen={showDeletePopup}
          toggle={this.toggleDelete}
          title="DELETE Product"
          className="mx-500"
          onSubmit={this.onSubmitDelete}
        >
          <div>
            <h6>Are you sure you want to Delete</h6>
            <div className="delete_btns">
              <Button
                color="grey border-none"
                className="fw-600 mt-2"
                onClick={() => this.toggleDelete()}
              >
                Cancel
              </Button>
              <Button
                color="primary border-none"
                className="fw-600 mt-2"
                onClick={() => this.onSubmitDelete()}
              >
                Confirm
              </Button>
            </div>
          </div>
        </CreateModel>
        <CreateModel
          isOpen={this.state.openCreatePopup}
          toggle={this.toggle}
          title="Product Detail"
          className="mx-1000"
        >
          {popupLoading ? (
            <Loader className="h-100" />
          ) : (
            <>
              <Row className=" no-gutters bg-white">
                <Col className="col-lg-12 col-12">
                  <Row>
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      className="col-12 custom-reselect"
                    >
                      <ReactSelect
                        type="select"
                        label="Select Category"
                        name="categoryId"
                        options={categoryDropdown}
                        value={productDetails["categoryId"]}
                        onChange={this.onChange}
                        error={errors["categoryId"]}
                      />
                    </Col>
                    <Col className="col-12" lg="4" md="4">
                      <FormInput
                        type="text"
                        label={`Product Name`}
                        name="productName"
                        onChange={(...params) => this.onChange(...params)}
                        value={productDetails["productName"]}
                        error={errors["productName"]}
                      />
                    </Col>
                    <Col className="col-12" lg="4" md="4">
                      <FormInput
                        type="number"
                        label={`Product Quantity`}
                        name="quantity"
                        onChange={(...params) => this.onChange(...params)}
                        value={productDetails["quantity"]}
                        error={errors["quantity"]}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="col-lg-12 mt-3">
                  <Row>
                    <Col className="col-12" lg="3" md="3">
                      <FormInput
                        type="number"
                        label={`Product Price`}
                        name="productPrice"
                        onChange={(...params) => this.onChange(...params)}
                        value={productDetails["productPrice"]}
                        error={errors["productPrice"]}
                      />
                    </Col>
                    <Col className="col-12" lg="3" md="3">
                      <FormInput
                        type="number"
                        label={`Slashed Price`}
                        name="slashedPrice"
                        onChange={(...params) => this.onChange(...params)}
                        value={productDetails["slashedPrice"]}
                        error={errors["slashedPrice"]}
                      />
                    </Col>
                    <Col className="col-12" lg="4" md="4">
                      <FormInput
                        type="textarea"
                        label={`Product Description`}
                        name="productDescription"
                        onChange={(...params) => this.onChange(...params)}
                        value={productDetails["productDescription"]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg="5"
                      md="5"
                      sm="5"
                      className="col-12 custom-reselect"
                    >
                      <ReactSelect
                        type="select"
                        label="Select Addon"
                        name="addonIds"
                        isMulti
                        options={addonDropdown}
                        value={productDetails["addonIds"]}
                        onChange={this.onChange}
                      />
                    </Col>
                    <Col className="col-12" lg="6" md="4">
                      <Uploadfile
                        label={"Product Image"}
                        name="productImageUrl"
                        id={"productImageUrl"}
                        uploadedFileName={
                          productDetails["productImageFileName"]
                        }
                        uploadedImageUrl={productDetails["productImageUrl"]}
                        isUploading={isUploadingImage}
                        onChange={this.onUpload}
                        isMult={false}
                        error={errors["productImageUrl"]}
                      ></Uploadfile>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="w-100 d-flex justify-content-end align-items-center my-2">
                <Col className="col-lg-6">
                  <ButtonType1
                    btnName={"Confirm"}
                    isLoading={btnLoading}
                    btnClassName={"primary_bg"}
                    onClick={() => this.onSubmit()}
                  />
                </Col>
                <Col className="col-lg-6">
                  <Button
                    type="button"
                    outline
                    color="lighGrey-2 w-100 border-primary"
                    className="fw-600"
                    onClick={this.toggle}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </CreateModel>
      </>
    );
  }
}

const mapStateToProps = ({ shops, profile, loader }) => {
  return {
    shops,
    profile,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(shops, dispatch);
};

const AddProduct = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProductClass);

export { AddProduct };

let inputValidations = {
  productName: {
    presence: {
      allowEmpty: false,
      message: "^Product Name can't be blank",
    },
  },
  quantity: {
    presence: {
      allowEmpty: false,
      message: "^Product Quantity can't be blank",
    },
  },
  productPrice: {
    presence: {
      allowEmpty: false,
      message: "^Product Price can't be blank",
    },
  },
  slashedPrice: {
    presence: {
      allowEmpty: false,
      message: "^Slashed Price can't be blank",
    },
  },
  productImageUrl: {
    presence: {
      allowEmpty: false,
      message: "^product Image can't be blank",
    },
  },
  categoryId: {
    presence: {
      allowEmpty: false,
      message: "^category can't be blank",
    },
  },
};
