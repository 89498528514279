import { main } from "../service/actionType";

const initialState = {
  customerList: {
    list: [],
    pageMeta: {
      count: 0,
      limit: 10,
      page: 1,
      totalPages: 0,
    },
  },
  customerDropdownList: [],
  customerHistory: {
    list: [],
    pageMeta: {
      count: 0,
      limit: 10,
      page: 1,
      totalPages: 0,
    },
  },
};
export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case main.GET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: {
          list: payload?.list,
          pageMeta: payload?.pageMeta,
        },
      };
    case main.GET_CUSTOMER_DROPDOWN_LIST:
      return {
        ...state,
        customerDropdownList: payload,
      };
    case main.GET_CUSTOMER_HISTORY:
      return {
        ...state,
        customerHistory: payload,
      };
    default:
      return state;
  }
};
