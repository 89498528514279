export const login = {
  ADMIN_LOGIN: "AUTH_ADMIN_LOGIN",
};
export const loaders = {
  IS_PRODUCTS_LIST_FETCHING: "IS_PRODUCTS_LIST_FETCHING",
  IS_NOTIFICATION_DETAIL_FETCHING: "IS_NOTIFICATION_DETAIL_FETCHING",
  IS_APP_FETCHING: "IS_CREATE_APP_FETCHING",
  IS_ORDERS_LIST_FETCHING: "IS_ORDERS_LIST_FETCHING",
  IS_FETCHING_PROFILE_DETAILS: "IS_FETCHING_PROFILE_DETAILS",
  IS_TABLE_DETAIL_FETCHING: "IS_TABLE_DETAIL_FETCHING",
  IS_PRODUCT_PROFIT_LIST_FETCHING: "IS_PRODUCT_PROFIT_LIST_FETCHING",
  GET_SELECTED_BOOKING_LOADING: "GET_SELECTED_BOOKING_LOADING",
  TOGGLE_STAFF_DETAILS: "TOGGLE_STAFF_DETAILS",
  IS_SHOP_DETAIL_FETCHING: "IS_SHOP_DETAIL_FETCHING",
  IS_ORDERS_LIST_FETCHING: "IS_ORDERS_LIST_FETCHING",
  IS_COUPON_DETAIL_FETCHING: "IS_COUPON_DETAIL_FETCHING",

  DASHBOARD_COUNTS_FETCHING: "DASHBOARD_COUNTS_FETCHING",
  DASHBOARD_ORDER_STATS_FETCHING: "DASHBOARD_ORDER_STATS_FETCHING",
  DASHBOARD_SHOP_ANALYTICS_FETCHING: "DASHBOARD_SHOP_ANALYTICS_FETCHING",
  DASHBOARD_TOP_CUSTOMERS_FETCHING: "DASHBOARD_TOP_CUSTOMERS_FETCHING",
  GET_PAST_DELIVERY_PERSON_ORDERS_LIST_FETCHING:
    "GET_PAST_DELIVERY_PERSON_ORDERS_LIST_FETCHING",
};

export const profileActionType = {
  UPDATE_PROFILE_DETAILS: "UPDATE_PROFILE_DETAILS",
};
export const main = {
  PRODUCTS_DROPDOWN_LIST: "PRODUCTS_DROPDOWN_LIST",
  GET_PAST_DELIVERY_PERSON_ORDERS_LIST: "GET_PAST_DELIVERY_PERSON_ORDERS_LIST",
  MONTHLY_ORDER_STATS: "MONTHLY_ORDER_STATS",
  GET_CUSTOMER_HISTORY: "GET_CUSTOMER_HISTORY",
  ORDER_STATS: "ORDER_STATS",
  DASHBOARD_COUNT: "DASHBOARD_COUNT",
  SHOP_ANALYTICS: "SHOP_ANALYTICS",
  TOP_CUSTOMERS: "TOP_CUSTOMERS",
  GET_ALL_DELIVERY_BOYS: "GET_ALL_DELIVERY_BOYS",
  GET_ORDER_DETAIL_PRODUCT_LIST: "GET_ORDER_DETAIL_PRODUCT_LIST",
  COUPON_LIST: "COUPON_LIST",
  PRODUCT_PROFIT_LIST: "PRODUCT_PROFIT_LIST",
  COUPON_FORM_CREATE: "COUPON_FORM_CREATE",
  CITY_TABLE_LIST: "CITY_TABLE_LIST",
  CITY_FORM_CREATE_OR_UPDATE: "CITY_FORM_CREATE_OR_UPDATE",
  LOCATION_FORM_RESET: "LOCATION_FORM_RESET",
  LOCATION_LIST: "LOCATION_LIST",
  BANNER_FORM_CREATE: "BANNER_FORM_CREATE",
  BANNER_TABLE_LIST: "BANNER_TABLE_LIST",
  BANNER_DROPDOWN_LIST: "BANNER_DROPDOWN_LIST",
  GET_NOTIFICATION_DETAIL: "GET_NOTIFICATION_DETAIL",
  NOTIFICATION_LIST: "NOTIFICATION_LIST",
  GET_DELIVERY_BOYS_PAYMENT: "GET_DELIVERY_BOYS_PAYMENT",
  GET_CUSTOMER_LIST: "GET_CUSTOMER_LIST",
  GET_CUSTOMER_DROPDOWN_LIST: "GET_CUSTOMER_DROPDOWN_LIST",
  RESET_SELECTED_BOOKINGID: "RESET_SELECTED_BOOKINGID",
  GET_ADMINS_LIST: "GET_ADMINS_LIST",
  ROLES_LIST: "ROLES_LIST",
  CATEGORY_DROPDOWN_LIST: "CATEGORY_DROPDOWN_LIST",
  SHOPS_LIST: "SHOPS_LIST",
  REVENUE_LIST: "REVENUE_LIST",
  PRODUCT_LIST: "PRODUCT_LIST",
  CATEGORY_LIST: "CATEGORY_LIST",
  SHOPS_DROPDOWN_LIST: "SHOPS_DROPDOWN_LIST",
  GET_ORDERS_LIST: "GET_ORDERS_LIST",
  GET_SHOP_CATEGORY_DETAIL: "GET_SHOP_CATEGORY_DETAIL",
  GET_PRODUCT_DETAIL: "GET_PRODUCT_DETAIL",
  GET_EMPLOYEES_LIST: "GET_EMPLOYEES_LIST",
  EMPLOYEE_MASTER_DETAIL: "EMPLOYEE_MASTER_DETAIL",
  GET_EMPLOYEE_DETAIL: "GET_EMPLOYEE_DETAIL",
  BOOKING_DETAIL_BY_ID: "BOOKING_DETAIL_BY_ID",
  SELECTED_BOOKING_ID: "SELECTED_BOOKING_ID",
  GET_SHOP_CATEGORY_PRODUCT_DETAIL: "GET_SHOP_CATEGORY_PRODUCT_DETAIL",
  SHOP_FORM_CREATE_OR_UPDATE: "SHOP_FORM_CREATE_OR_UPDATE",
  SHOP_FORM_RESET: "SHOP_FORM_RESET",
  GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LISt:
    "GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LIST",
  GET_DELIVERED_LIST: "GET_DELIVERED_LIST",
  GET_CUSTOMER_PAYMENT_LIST: "GET_CUSTOMER_PAYMENT_LIST",
  GET_SHOP_PAYMENT_LIST: "GET_SHOP_PAYMENT_LIST",
  GET_SUPPORT_LIST: "GET_SUPPORT_LIST",
};

export const report = {
  IS_REPORTS_MASTER_LIST_FETCHING: "IS_REPORTS_MASTER_LIST_FETCHING",
  IS_SELECTED_REPORT_LOADING: "IS_SELECTED_REPORT_LOADING",

  REPORTS_MASTER_LIST: "REPORTS_MASTER_LIST",
};
