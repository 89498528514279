import { loaders, onboard, main } from "service/actionType";
import { API } from "service/api";
import { API_BOOK } from "service/endpoints";
import { Toast } from "../helpers/Toast";

export const getSupportList = (payload) => async (dispatch, getState) => {
  try {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true });
    let { GET_SUPPORT_LIST_API } = API_BOOK.MAIN_APP;
    let requestPayload = {
      ...GET_SUPPORT_LIST_API,
      params: {
        ...payload,
      },
    };
    let response = await API(requestPayload);
    console.log(response);
    if (response.status === 200) {
      dispatch({ type: main.GET_SUPPORT_LIST, payload: response.data.data });
      dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false });
    } else {
      dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false });
    }
  } catch (error) {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false });
  }
};
