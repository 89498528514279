let endpoints = {
  UPLOAD_DOC: "/api/admin/upload/single",

  LOGIN: "/api/admin/user/login",
  VERIFY_TOKEN: "/api/admin/user/verify/token",

  // dashboard
  DASHBOARD_COUNTS: "api/admin/dashboard/counts",
  ORDERS_STATS: "api/admin/dashboard/year/orders/stats",
  SHOP_ANALYTICS: "api/admin/dashboard/shop/analytics",
  TOP_CUSTOMERS: "api/admin/dashboard/topcustomers",
  MONTHLY_ORDERS_STATS: "api/admin/dashboard/monthly/orders/stats",

  // shops
  SHOPS_LIST: "/api/admin/shop/list",
  UPDATE_SHOP_STATUS_API: "api/admin/shop/updateShop",
  SHOPS_DROPDOWN_LIST: "/api/admin/shop/dropdown/list",
  CREATE_SHOP: "/api/admin/shop/create",
  UPDATE_SHOP: "/api/admin/shop/edit",
  SHOP_DETAIL: "/api/admin/shop/detail",
  DELETE_SHOP: "/api/admin/shop/delete",

  // CATEGORY
  CATEGORY_LIST: "/api/admin/category/list",
  CREATE_CATEGORY: "/api/admin/category/create",
  UPDATE_CATEGORY: "/api/admin/category/edit",
  UDPATE_CATEGORY_STATUS: "/api/admin/category/edit",
  GET_CATEGORY_DROPDOWN: "/api/admin/category/dropdown",
  GET_ADDON_DROPDOWN: "/api/admin/product/addon/dropdown",
  DELETE_CATEGORY: "/api/admin/category/delete",

  // PRODUCT
  PRODUCT_LIST: "/api/admin/product/list",
  CREATE_PRODUCT: "/api/admin/product/create",
  UPDATE_PRODUCT: "/api/admin/product/edit",
  UDPATE_PRODUCT_STATUS: "/api/admin/product/update/status",
  GET_PRODUCTS_DROPDOWN: "/api/admin/product/dropdown",
  DELETE_PRODUCT: "/api/admin/product/delete",

  // ORDERS
  GET_ORDERS_LIST: "/api/admin/order/list",
  GET_BOOKING_DETAIL_BY_ID: "/api/admin/order/detail",
  UPDATE_BOOKING_DETAIL: "/api/admin/order/update/status",
  EDIT_ORDER_API: "/api/admin/order/edit",
  CREATE_ORDER_API: "/api/admin/order/create",
  UPDATE_ORDER_API: "/api/admin/order/update",

  GET_AVAILABLE_DELIVERY_BOYS: "/api/admin/deliveryboy/available/list",

  GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LISt:
    "/api/admin/deliveryboy/assigned/list",
  UPDATE_DELIVERY_BOY_ORDER_STATUS:
    "/api/admin/deliveryboy/update/order/status",
  GET_DELIVERY_BOYS_PAYMENT_API: "/api/admin/deliveryboy/payment/list", //for admin alons access
  UPDATE_PAYMENT_SENT_TO_DELIVERYBOY_API:
    "/api/admin/deliveryboy/update/payment/sent", //for admin alons access
  GET_PAST_DELIVERY_PERSON_ORDERS_LIST:
    "/api/admin/deliveryboy/past/orders/list",

  // Customers
  CUSTOMER_LIST_API: "/api/admin/customer/list",
  EDIT_CUSTOMER: "/api/admin/customer/edit",
  CUSTOMER_DETAIL_API: "/api/admin/customer/detail",
  CUSTOMER_HISTORY: "/api/admin/customer/history",

  GET_CUSTOMER_PAYMENT_LIST: "/api/admin/payment/customer/list",
  GET_SHOP_PAYMENT_LIST: "/api/admin/payment/shop/list",
  REVENUE_LIST_API: "/api/admin/revenue",
  SEARCH_CUSTOMER: "/api/admin/customer/search/list",
  UPDATE_PAYMENT_SENT_TOSHOP_API: "/api/admin/payment/shop/sent/update",
  CREATE_CUSTOMER: "/api/admin/customer/create",

  // usermanagement
  ROLES_LIST: "/api/admin/user/roles/list",
  ADMINS_LIST: "/api/admin/user/list",
  GET_ADMIN_BY_ID: "/api/admin/user/detail",
  CREATE_USER: "/api/admin/user/create",
  UDPATE_USER: "/api/admin/user/edit",
  UPDATE_USER_STATUS: "/api/admin/user/update/status",

  // notification
  NOTIFICATION_LIST_API: "/api/admin/notification/list",
  CREATE_NOTIFICATION: "api/admin/notification/create",
  UPDATE_NOTIFICATION: "api/admin/notification/update",
  SEND_NOTIFICATION: "/api/admin/notification/send",
  GET_NOTIFICATION_DETAIL_API: "/api/admin/notification/notificationdetail",
  GET_CUSTOMER_DROPDOWN_API: "/api/admin/customer/dropdown/list",

  // locations
  CITY_LIST: "/api/admin/location/city/list",
  CITY_TABLE_LIST: "/api/admin/location/list",
  CREATE_OR_UPDATE_CITY: "/api/admin/location/city/create",

  // banner
  BANNER_TABLE_LIST: "/api/admin/banner/list",
  BANNER_DROPDOWN_LIST: "/api/admin/banner/type/dropdown",
  BANNER_CREATE: "/api/admin/banner/create",

  // coupons
  CREATE_COUPON: "/api/admin/coupon/create",
  COUPON_LIST: "/api/admin/coupon/list",
  UDPATE_COUPON_AVAILABILITY_STATUS: "/api/admin/coupon/availability/status",
  GET_COUPON_BY_ID: "/api/admin/coupon/getbyid",
  UPDATE_COUPON: "/api/admin/coupon/update/detail",

  // PROFITS
  PRODUCTS_PROFIT_LIST: "/api/admin/profits/product/wise",

  // REPORTS
  GET_REPORTS_MASTER_LIST: "/api/admin/reports/master/list",

  //SUPPORT
  GET_SUPPORT_LIST_API: "/api/admin/support/list",
};

export let API_BOOK = {
  ADMIN_MANAGEMENT: {
    SESSION_API: {
      LOGIN_API: {
        url: endpoints.LOGIN,
        method: "POST",
        data: null,
        headers: {},
        baseUrlType: "BASE_URL",
      },
      VERIFY_TOKEN_API: {
        url: endpoints.VERIFY_TOKEN,
        method: "GET",
        data: null,
        headers: {},
        baseUrlType: "BASE_URL",
      },
    },
  },
  MAIN_APP: {
    PRODUCTS_PROFIT_LIST: {
      url: endpoints.PRODUCTS_PROFIT_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    MONTHLY_ORDERS_STATS_API: {
      url: endpoints.MONTHLY_ORDERS_STATS,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CUSTOMER_HISTORY_API: {
      url: endpoints.CUSTOMER_HISTORY,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    DASHBOARD_COUNTS_API: {
      url: endpoints.DASHBOARD_COUNTS,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    ORDERS_STATS_API: {
      url: endpoints.ORDERS_STATS,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    SHOP_ANALYTICS_API: {
      url: endpoints.SHOP_ANALYTICS,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    TOP_CUSTOMERS_API: {
      url: endpoints.TOP_CUSTOMERS,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_ORDER_API: {
      url: endpoints.UPDATE_ORDER_API,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    EDIT_ORDER_API: {
      url: endpoints.EDIT_ORDER_API,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CREATE_ORDER_API: {
      url: endpoints.CREATE_ORDER_API,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CREATE_COUPON_API: {
      url: endpoints.CREATE_COUPON,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_COUPON_API: {
      url: endpoints.UPDATE_COUPON,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    COUPON_LIST_API: {
      url: endpoints.COUPON_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UDPATE_COUPON_AVAILABILITY_STATUS: {
      url: endpoints.UDPATE_COUPON_AVAILABILITY_STATUS,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_COUPON_BY_ID: {
      url: endpoints.GET_COUPON_BY_ID,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CITY_TABLE_LIST_API: {
      url: endpoints.CITY_TABLE_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_CUSTOMER_DROPDOWN_API: {
      url: endpoints.GET_CUSTOMER_DROPDOWN_API,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CUSTOMER_DETAIL_API: {
      url: endpoints.CUSTOMER_DETAIL_API,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CREATE_NOTIFICATION_API: {
      url: endpoints.CREATE_NOTIFICATION,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UDPATE_NOTIFICATION_API: {
      url: endpoints.UPDATE_NOTIFICATION,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_NOTIFICATION_DETAIL_API: {
      url: endpoints.GET_NOTIFICATION_DETAIL_API,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    NOTIFICATION_LIST_API: {
      url: endpoints.NOTIFICATION_LIST_API,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },

    SEND_NOTIFICATION_API: {
      url: endpoints.SEND_NOTIFICATION,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_SHOP_STATUS_API: {
      url: endpoints.UPDATE_SHOP_STATUS_API,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    DELETE_SHOP_API: {
      url: endpoints.DELETE_SHOP,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_PAYMENT_SENT_TO_DELIVERYBOY_API: {
      url: endpoints.UPDATE_PAYMENT_SENT_TO_DELIVERYBOY_API,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_DELIVERY_BOYS_PAYMENT_API: {
      url: endpoints.GET_DELIVERY_BOYS_PAYMENT_API,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CUSTOMER_LIST_API: {
      url: endpoints.CUSTOMER_LIST_API,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    SEARCH_CUSTOMER_API: {
      url: endpoints.SEARCH_CUSTOMER,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CREATE_CUSTOMER_API: {
      url: endpoints.CREATE_CUSTOMER,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    EDIT_CUSTOMER_API: {
      url: endpoints.EDIT_CUSTOMER,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    ADMINS_LIST_API: {
      url: endpoints.ADMINS_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    ROLES_LIST_API: {
      url: endpoints.ROLES_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_ADMIN_BY_ID: {
      url: endpoints.GET_ADMIN_BY_ID,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_USER_STATUS_API: {
      url: endpoints.UPDATE_USER_STATUS,
      method: "PUT",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CREATE_USER_API: {
      url: endpoints.CREATE_USER,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_USER_API: {
      url: endpoints.UDPATE_USER,
      method: "PUT",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_PAYMENT_SENT_TOSHOP_API: {
      url: endpoints.UPDATE_PAYMENT_SENT_TOSHOP_API,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    REVENUE_LIST_API: {
      url: endpoints.REVENUE_LIST_API,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UDPATE_CATEGORY_STATUS_API: {
      url: endpoints.UDPATE_CATEGORY_STATUS,
      method: "PUT",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    DELETE_CATEGORY_API: {
      url: endpoints.DELETE_CATEGORY,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UDPATE_PRODUCT_STATUS_API: {
      url: endpoints.UDPATE_PRODUCT_STATUS,
      method: "PUT",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_PRODUCTS_DROPDOWN_API: {
      url: endpoints.GET_PRODUCTS_DROPDOWN,
      method: "get",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    DELETE_PRODUCT_API: {
      url: endpoints.DELETE_PRODUCT,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_DELIVERY_BOY_ORDER_STATUS_API: {
      url: endpoints.UPDATE_DELIVERY_BOY_ORDER_STATUS,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPLOAD_DOC_API: {
      url: endpoints.UPLOAD_DOC,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CREATE_SHOP_API: {
      url: endpoints.CREATE_SHOP,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_SHOP_API: {
      url: endpoints.UPDATE_SHOP,
      method: "PUT",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CREATE_CATEGORY_API: {
      url: endpoints.CREATE_CATEGORY,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_CATEGORY_API: {
      url: endpoints.UPDATE_CATEGORY,
      method: "PUT",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CREATE_PRODUCT_API: {
      url: endpoints.CREATE_PRODUCT,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_PRODUCT_API: {
      url: endpoints.UPDATE_PRODUCT,
      method: "PUT",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_SHOP_PAYMENT_LIST_API: {
      url: endpoints.GET_SHOP_PAYMENT_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_CUSTOMER_PAYMENT_LIST_API: {
      url: endpoints.GET_CUSTOMER_PAYMENT_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    SHOPS_LIST_API: {
      url: endpoints.SHOPS_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    SHOPS_DROPDOWN_LIST_API: {
      url: endpoints.SHOPS_DROPDOWN_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    CATEGORY_LIST_API: {
      url: endpoints.CATEGORY_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    PRODUCT_LIST_API: {
      url: endpoints.PRODUCT_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    SHOP_DETAIL_API: {
      url: endpoints.SHOP_DETAIL,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_SHOPS_DROPDOWN_API: {
      url: endpoints.GET_SHOPS_DROPDOWN,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_CATEGORY_DROPDOWN_API: {
      url: endpoints.GET_CATEGORY_DROPDOWN,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_ADDON_DROPDOWN_API: {
      url: endpoints.GET_ADDON_DROPDOWN,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_ORDERS_LIST_API: {
      url: endpoints.GET_ORDERS_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    UPDATE_BOOKING_DETAIL_API: {
      url: endpoints.UPDATE_BOOKING_DETAIL,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_BOOKING_DETAIL_BY_ID_API: {
      url: endpoints.GET_BOOKING_DETAIL_BY_ID,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_AVAILABLE_DELIVERY_BOYS_API: {
      url: endpoints.GET_AVAILABLE_DELIVERY_BOYS,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    EMPLOYEES_MASTER_API: {
      url: endpoints.EMPLOYEES_MASTER,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_EMPLOYEE_DETAIL_API: {
      url: endpoints.GET_EMPLOYEE_DETAIL,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LIST_API: {
      url: endpoints.GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LISt,
      method: "POST",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_PAST_DELIVERY_PERSON_ORDERS_LIST_API: {
      url: endpoints.GET_PAST_DELIVERY_PERSON_ORDERS_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
    GET_SUPPORT_LIST_API: {
      url: endpoints.GET_SUPPORT_LIST_API,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
  },
  REPORTS: {
    GET_REPORTS_MASTER_LIST_API: {
      url: endpoints.GET_REPORTS_MASTER_LIST,
      method: "GET",
      data: null,
      headers: {},
      baseUrlType: "BASE_URL",
    },
  },
};
