import React, { Component } from "react";
import { Row, Col, Button, CustomInput } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CategoryColumns } from "helpers/tableDataMapper";
import * as shops from "actions/shops";
import FormInput from "components/inputs/FormInput";
import { CreateModel } from "components/Model";
import Uploadfile from "components/inputs/Uploadfile";
import { Toast } from "helpers/Toast";
import { API, API_BOOK } from "service";
import { SearchWrapper } from "components/Table/SearchWrapper";
import validate from "helpers/validation";
import Topbar from "components/topbar";
import { formatDate, handleTableCellData } from "helpers";
import { getSNo } from "helpers/utils";
import { TableWrapper } from "components/TableWrapper";
import { TableActionbtns } from "components/TableWrapper/tableActionBtns";

const defaultPayload = {
  search: "",
  startDate: "",
  page: 1,
  limit: 50,
};

class AddCategoryClass extends Component {
  defaultcategoryObject = {
    _id: "",
    categoryName: "",
    categoryImageUrl: null,
  };
  state = {
    activeTab: [1],
    queryPayload: {
      search: null,
      page: 1,
      limit: 50,
    },
    categoriesList: [],
    categoryDetail: {
      _id: null,
      categoryName: null,
      categoryImageUrl: null,
    },
    errors: {
      categoryName: null,
      categoryImageUrl: null,
    },
    init: true,
    openCreatePopup: false,
    btnLoading: false,
    isUploadingImage: false,
    showDeletePopup: false,
    deleteData: null,
  };
  componentDidMount() {
    this.getCategoryList();
  }

  clearFilters = () => {
    this.setState(
      {
        queryPayload: { ...defaultPayload },
      },
      this.getCategoryList
    );
  };

  nextpage = (page) => {
    let { queryPayload } = this.state;
    queryPayload["page"] = page;
    this.setState({ queryPayload }, () => this.getCategoryList(page));
  };

  getCategoryList = (page = 1) => {
    let { queryPayload } = this.state;
    queryPayload["page"] = page;
    this.props.getCategoryList(queryPayload);
  };

  onChangeQueryPayload = (name, value) => {
    let { queryPayload } = this.state;
    queryPayload[name] = value;
    this.setState({
      queryPayload,
    });
  };

  onChange = (name, value) => {
    let { categoryDetail } = this.state;
    categoryDetail[name] = value;
    this.setState({ categoryDetail });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { categoryDetail, isEdit } = this.state;

    const notValid = validate(categoryDetail, inputValidations);
    if (notValid) {
      this.setState({
        errors: notValid,
      });
    } else {
      this.setState({
        btnLoading: true,
      });
      let payload = {
        categoryName: categoryDetail.categoryName,
        categoryImageUrl: categoryDetail.categoryImageUrl,
      };
      let response = null;
      if (isEdit) {
        payload["_id"] = categoryDetail._id;
        response = await this.props.updateCategory(payload);
      } else {
        response = await this.props.createCategory(payload);
      }
      if (response != null && response.status) {
        this.setState(
          {
            openCreatePopup: false,
            btnLoading: false,
            categoryDetail: { ...this.defaultcategoryObject },
          },
          this.getCategoryList
        );
      } else {
        this.setState({
          btnLoading: false,
        });
      }
    }
  };

  handleSelectRow = (data) => {
    const payload = {
      _id: data._id,
      categoryName: data.categoryName,
      categoryImageUrl: data.categoryImageUrl,
    };
    this.setState({
      openCreatePopup: true,
      isEdit: true,
      categoryDetail: payload,
    });
  };

  toggle = () => {
    this.setState({
      openCreatePopup: !this.state.openCreatePopup,
      categoryDetail: { ...this.defaultcategoryObject },
    });
  };

  onUpload = async (name, files) => {
    let { errors, categoryDetail } = this.state;
    errors[name] = undefined;
    let formData = new FormData();
    formData.append("image", files[0]);
    this.setState({ isUploadingImage: true });
    const { UPLOAD_DOC_API } = API_BOOK.MAIN_APP;
    let requestPayload = {
      ...UPLOAD_DOC_API,
      data: formData,
    };
    let response = await API(requestPayload);
    if (response.status === 200) {
      const newDetail = {
        ...categoryDetail,
        categoryImageUrl: response.data.data.imageUrl,
      };
      this.setState({
        categoryDetail: newDetail,
        isUploadingImage: false,
      });
    } else {
      Toast({ type: "error", message: response.data.message });
    }
    this.setState({ isUploadingImage: false });
  };

  toggleCategoryStatus = (category) => {
    let data = {
      _id: category._id,
      status: category.status === 1 ? 2 : 1,
    };
    this.props.toggleCategoryStatus(data);
  };

  deleteRow = (row) => {
    let data = {
      _id: row._id,
    };
    this.setState({
      showDeletePopup: true,
      deleteData: data,
    });
  };

  onSubmitDelete = async () => {
    let { deleteData } = this.state;
    await this.props.deleteCategory(deleteData);
    this.setState({ showDeletePopup: false }, this.getCategoryList);
  };

  toggleDelete = () => {
    this.setState({
      showDeletePopup: false,
    });
  };

  render() {
    let { loader, shops } = this.props;
    let {
      errors,
      categoryDetail,
      isUploadingImage,
      queryPayload,
      showDeletePopup,
    } = this.state;
    return (
      <>
        <Topbar
          pageName="Categories"
          showBtn={true}
          createBtnLabel="Create Category"
          onPressCreateBtn={() => this.toggle()}
        />
        <SearchWrapper
          applyFilter={() => this.getCategoryList()}
          clearFilters={() => this.clearFilters()}
        >
          <div className="row">
            <div className="col-lg-3 col-xl-3 col-md-4 col-12  mb-3">
              <FormInput
                type="text"
                name="search"
                label="Search"
                placeholder="Search.."
                onChange={(...params) => this.onChangeQueryPayload(...params)}
                value={queryPayload["search"]}
              />
            </div>
          </div>
        </SearchWrapper>
        <TableWrapper
          headerDetails={CategoryColumns}
          pageMeta={shops.categoriesList?.pageMeta}
          listLength={shops.categoriesList?.list || []}
          isLoading={loader.isTableDetailsFetching}
          handlePageChange={this.nextpage}
          highlightRows
          tableClassName="mt-3"
        >
          {shops.categoriesList?.list?.map((item, index) => {
            const {
              _id = "",
              categoryName = "",
              categoryImageUrl = "",
              status = "",
              createdAt = "",
            } = item;
            return (
              <tr>
                {handleTableCellData(
                  getSNo(
                    index,
                    shops.categoriesList?.pageMeta?.page,
                    shops.categoriesList?.pageMeta?.limit
                  ),
                  "-",
                  "text-center"
                )}
                {handleTableCellData(
                  _id.slice(_id.length - 6),
                  "-",
                  "text-center"
                )}
                {handleTableCellData(categoryName, "-", "text-center")}
                <td>
                  <img
                    src={categoryImageUrl}
                    style={{ width: "75px", height: "75px", borderRadius: 5 }}
                    alt="category"
                  />
                </td>
                <td>
                  <CustomInput
                    type="switch"
                    id={_id}
                    name="isActive"
                    inline={true}
                    label={status === 1 ? "ON" : "OFF"}
                    checked={status === 1 ? true : false}
                    onChange={() => this.toggleCategoryStatus(item)}
                  />
                </td>
                {handleTableCellData(formatDate(createdAt), "-", "text-center")}
                <td>
                  <TableActionbtns
                    item={item}
                    deleteRow={this.deleteRow}
                    handleSelectRow={this.handleSelectRow}
                  />
                </td>
              </tr>
            );
          })}
        </TableWrapper>
        <CreateModel
          isOpen={showDeletePopup}
          toggle={this.toggleDelete}
          title="DELETE CATEGORY"
          className="mx-500"
        >
          <div>
            <h6>Are you sure you want to Delete</h6>
            <div className="delete_btns">
              <Button
                color="grey border-none"
                className="fw-600 mt-2"
                onClick={() => this.toggleDelete()}
              >
                Cancel
              </Button>
              <Button
                color="primary border-none"
                className="fw-600 mt-2"
                onClick={() => this.onSubmitDelete()}
              >
                Confirm
              </Button>
            </div>
          </div>
        </CreateModel>
        <CreateModel
          isOpen={this.state.openCreatePopup}
          toggle={this.toggle}
          onSubmit={this.onSubmit}
          title="Category Detail"
        >
          <form onSubmit={this.onSubmit}>
            <Row>
              <Col lg="5" md="4" sm="5">
                <FormInput
                  type="text"
                  label={`Category Name`}
                  name="categoryName"
                  value={categoryDetail["categoryName"]}
                  onChange={(...params) => this.onChange(...params)}
                  error={errors["categoryName"]}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="5" md="4" sm="5">
                <Uploadfile
                  label={"category Image"}
                  id={"categoryImageUrl"}
                  name="categoryImageUrl"
                  uploadedFileName={categoryDetail["categoryImageFileName"]}
                  uploadedImageUrl={categoryDetail["categoryImageUrl"]}
                  isUploading={isUploadingImage}
                  onChange={this.onUpload}
                  isMult={false}
                  error={errors["categoryImageUrl"]}
                ></Uploadfile>
              </Col>
            </Row>

            <Row className="w-100 align-items-center">
              <Col className="col-lg-6">
                <Button
                  color="primary w-100 border-none"
                  className="fw-600"
                  type="submit"
                >
                  Confirm
                </Button>
              </Col>
              <Col className="col-lg-6">
                <Button
                  type="button"
                  outline
                  color="lighGrey-2 w-100 border-primary"
                  className="fw-600"
                  onClick={this.toggle}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CreateModel>
      </>
    );
  }
}

const mapStateToProps = ({ shops, profile, loader }) => {
  return {
    shops,
    profile,
    loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(shops, dispatch);
};

const AddCategory = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCategoryClass);

export { AddCategory };

let inputValidations = {
  categoryName: {
    presence: {
      allowEmpty: false,
      message: "^category Name can't be blank",
    },
  },
  categoryImageUrl: {
    presence: {
      allowEmpty: false,
      message: "^Category Image can't be blank",
    },
  },
};
