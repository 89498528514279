import { main } from "../service/actionType";

const initialState = {
  supportList: {
    list: [],
    pageMeta: {
      count: 0,
      limit: 10,
      page: 1,
      totalPages: 0,
    },
  },
};
export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case main.GET_SUPPORT_LIST:
      return {
        ...state,
        supportList: {
          list: payload?.list,
          pageMeta: payload?.pageMeta,
        },
      };

    default:
      return state;
  }
};
