import config from '../config';
import { history } from '../helpers'
import { axios } from "./axios";
import {Toast} from 'helpers/Toast'

export const API = async ({ url, method, headers = null, responseType, baseUrlType, data, params }) => {
    let axiosRequestObject = {
      method,
      headers,
      url,
      baseURL: config.api.BASE_URL,
      data,
      responseType:responseType,
      params
    };
  
    let request = await axios(axiosRequestObject)
    .then(handleSuccessRequest)
    .catch(handleErrorRequest);

    return request;
};

const handleErrorRequest = (err) => {
  if(!err.response) return Promise.reject()
  if(err.response?.status === 401){
    if(parseInt(localStorage.getItem('role')) && localStorage.getItem('role') !== null){
      history.replace('/auth/login')
    } else {
      history.replace('/auth/login')
    }
    localStorage.clear()
    Toast({ type: 'error', message: 'Unauthorized!!'})

  }
  if (err.response) return err.response;
  else
    return {
      status: "500",
      data: {
        message: "Internal server error!!",
      },
    };
};

const handleSuccessRequest = (response) => {
  return {
    status: response.status,
    data: response.data,
  }
};
